import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import HandoverSkuDetails from 'wms/handover/HandoverSkuDetails';
import { Router } from '@reach/router';

export default function HanoverSkuDetailsContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Router>
          <HandoverSkuDetails path="/wms/handoverSkuDetails/:skuId/:skuCode" />
        </Router>
      </Layout>
    </ReactQueryConfigProvider>
  );
}
