import produce from 'immer';

export const actions = {
  INIT: 'INIT',
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
};

const handoverSkuDetailsReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        return {
          ...data,
          details: data,
          actualHuList: data?.hus,
          filteredHuList: data?.hus,
        };
      }

      case actions.FILTER: {
        let fList = [];
        if (data === 'ALL') {
          fList = state.actualHuList;
        } else {
          fList = state.actualHuList.filter((item) => item.state === data);
        }

        draft.filteredHuList = fList;
        break;
      }

      case actions.SEARCH: {
        const searchedList =
          data === ''
            ? state.actualHuList
            : state.actualHuList.filter((item) => item.code.toLowerCase().includes(data));
        draft.filteredHuList = searchedList;
        break;
      }

      default:
        return state;
    }
  });
};

export default handoverSkuDetailsReducer;

export const filterOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ONGOING', label: 'Ongoing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
];
