import React, { useState, useReducer, useRef } from 'react';
import Select from 'react-select';
import LayersIcon from '@material-ui/icons/Layers';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useQuery, useMutation } from 'react-query';
import fetch from 'utils/fetch';
import { useBranchId } from 'utils/customHooks';
import handoverSkuDetailsReducer, { actions, filterOptions } from './handoverSkuDetailsReducer';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from 'components/inputs/ButtonM';
import ConfirmationDialog from 'components/ConfirmationDialog';

export default function HandoverSkuDetails(props) {
  const { skuId } = props;
  const branchId = useBranchId();
  const [state, dispatch] = useReducer(handoverSkuDetailsReducer, {});
  const currentDeleteHuId = useRef();
  const dialogForceCompleteConfirm = useRef();
  const dialogDeleteConfirm = useRef();

  const { refetch: refetchSkuDetails, isFetching: isFetchingSkuDetails } = useQuery(
    branchId && ['handoverSkuDetails', { skuId, branchId }],
    () =>
      fetch({
        url: `wms/handover/task/${skuId}/details`,
        method: 'GET',
        headers: {
          'x-wh-id': branchId,
        },
      }),
    {
      onSuccess: (data) => {
        //console.log('Sku Details response : ', data);
        dispatch([actions.INIT, data]);
      },
    }
  );

  const [forceCompleteTask] = useMutation(
    ({ whId, taskId }) =>
      fetch({
        url: `wms/handover/task/${taskId}/compelete`,
        method: 'POST',
        params: {
          whId: whId,
        },
      }),
    {
      onSuccess: () => {
        refetchSkuDetails();
      },
    }
  );

  const [deleteHu] = useMutation(
    ({ whId, taskId, huId }) =>
      fetch({
        url: `wms/handover/task/${taskId}/hu/${huId}`,
        method: 'DELETE',
        params: {
          whId: whId,
        },
      }),
    {
      onSuccess: () => {
        refetchSkuDetails();
      },
    }
  );

  function onSearch(value) {
    dispatch([actions.SEARCH, value.toLowerCase()]);
  }

  return (
    <div>
      <div className="flex flex-col w-full h-full">
        {isFetchingSkuDetails ? (
          <LinearProgress
            classes={{
              colorPrimary: 'bg-green-300',
            }}
          />
        ) : null}
        {/* Section containing Sku info and details */}
        <div className="flex flex-col">
          <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mt-5 mx-5">
            {state?.details?.code}
          </p>
          <div className="flex flex-row mt-5">
            <div className="flex-1">
              <p className="  text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {state?.details?.sku?.code}
              </p>
              <p className="text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {state?.details?.sku?.name}
              </p>
              <p className="  text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {`Batch: ${state?.details?.batch}`}
              </p>
            </div>
            <div className="flex-1">
              <p className="text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {`Full Cases : ${state?.details?.L2Progress?.done}/${state?.details?.L2Progress?.target}`}
              </p>
              <p className="text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {`Pieces : ${state?.details?.L0Progress?.done}/${state?.details?.L0Progress?.target}`}
              </p>
              <p className="text-base md:text-base antialiased sm:subpixel-antialiased md:antialiased font-normal md:font-normal mx-5">
                {`Total HU's scanned : ${state?.actualHuList?.length}`}
              </p>
            </div>
          </div>
        </div>
        {/* Section containing Filters */}
        <div className="flex flex-row justify-center items-center mt-5">
          <div className="flex-1">
            <p className="text-base md:text-lg antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              SKU Details
            </p>
          </div>
          <div className="flex flex-row-reverse flex-3 justify-center items-center space-x-2">
            <Select
              className="w-52 h-9 rounded-sm md:rounded-sm shadow-sm md:shadow-sm px-2 my-2"
              placeholder="Filter by status"
              options={filterOptions}
              onChange={(value) => dispatch([actions.FILTER, value.value])}
            />
            <input
              className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
              placeholder="Search by HU code"
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-3 md:gap-3 p-5">
          {state?.filteredHuList?.map((item) => {
            return (
              <HuItem
                key={item?.id}
                hu={item}
                onDeleteClicked={(huId) => {
                  //deleteHu({ whId: branchId, taskId: skuId, huId: huId });
                  currentDeleteHuId.current = huId;
                  dialogDeleteConfirm.current.openDialog();
                }}
              />
            );
          })}
        </div>

        <div className="fixed bottom-3 inset-x-1/2 justify-center items-center">
          <Button
            onClick={() => {
              dialogForceCompleteConfirm.current.openDialog();
            }}
          >
            Complete
          </Button>
        </div>
      </div>

      {/* Dialog Section for force complete confirmation  */}
      <ConfirmationDialog
        ref={dialogForceCompleteConfirm}
        title="Please confirm your action"
        desc="Your are attempting to complete the task forcefully. Are you sure about
        your choice ?"
        yes="Yes, Go ahead"
        no="Disagree"
        onYes={() => {
          forceCompleteTask({ whId: branchId, taskId: skuId });
        }}
        closeOnYes={true}
      />

      {/* Dialog Section for delete confirmation  */}
      <ConfirmationDialog
        ref={dialogDeleteConfirm}
        title="Please confirm your action"
        desc="Your are attempting to delete the handling unit. Are you sure about your choice ?"
        yes="Yes, Go ahead"
        no="Disagree"
        onYes={() => {
          deleteHu({ whId: branchId, taskId: skuId, huId: currentDeleteHuId.current });
        }}
        closeOnYes={true}
      />
    </div>
  );
}

export function HuItem(props) {
  const { hu, onDeleteClicked } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white p-3">
      <div className="flex flex-row flex-grow items-center">
        {/* HU Icon/Image section */}
        <div>
          <LayersIcon />
        </div>

        {/* HU info section */}
        <div className="mx-5">
          <p className={`text-base md:text-base font-bold md:font-bold`}>{hu?.code}</p>
          <p className="text-xs md:text-xs font-medium md:font-medium">
            {hu?.open ? 'Opened Case' : 'Full Case'}
          </p>
          <p className="text-xs md:text-xs font-medium md:font-medium">{hu?.qtyL0}</p>
        </div>

        {/*Action options*/}
        <div className="flex-1 flex-row flex-grow">
          <div className="float-right">
            <IconButton
              size="small"
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  onDeleteClicked(hu?.id);
                  setAnchorEl(null);
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
